@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

::-webkit-scrollbar {
  width: 10px; /* Narrow scrollbar width */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: Change background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #3b82f6; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Optional: Adjust border to make it appear detached */
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Narrow scrollbar width */
  scrollbar-color: #3b82f6 #f1f1f1; /* Scrollbar color and background track color */
}


body {
font-family: 'Roboto';
}

.hide-scrollbar {
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
  
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

.arabic {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.arabic-bold {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}



@keyframes spinn {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.animate-spin2 {
  animation: spinn 1s linear infinite;
}


#LOADER_CONT {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: show 0.5s 3s ease-in-out forwards
}

#LOADER {
  width: 250px;
  height: 250px;
}

@keyframes show {
  from {
    display: flex;
  }
  to {
    opacity: 0;
    display: none;
  }
}

#LOADER #loader {
  stroke: #000; /* Starting stroke color */
  stroke-width: 6; /* Increase stroke width to appear semibold */
  fill: none;
  stroke-dasharray: 1000; /* Adjust based on the path length */
  stroke-dashoffset: 200;
  animation: dashAnimation 3s ease-in-out forwards, colorPulse 3s ease-in-out;
}

@keyframes dashAnimation {
  0% {
    stroke-dashoffset: 1000; /* Starting with a bigger dash offset */
    stroke-width: 6; /* Semibold stroke */
  }
  50% {
    stroke-dashoffset: 100; /* Almost fully drawn */
    stroke-width: 8; /* Thicken stroke more for emphasis */
  }
  80% {
    stroke-dashoffset: 0; /* Fully drawn */
    stroke-width: 6; /* Back to semibold stroke */
  }
  100% {
    stroke-dasharray: 0; /* Back to normal shape without dash effect */
    stroke-dashoffset: 0;
    stroke-width: 6; /* Final semibold stroke */
  }
}

@keyframes colorPulse {
  0%, 100% {
    stroke: #000; /* Original color */
  }
  50% {
    stroke: #0e47a3; /* Transition to a lively color */
  }
}